import React from "react";
import { renderToString } from "react-dom/server";

import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

import BlogHeader from "../components/Blog/BlogHeader";
import OldContent from "../components/Blog/OldContent";

import Sidebar from "../components/Blog/Sidebar";
import PostContent from "../components/Blog/PostContent";
import useSiteMetadata from "../components/SiteMetadata";
import Seo from "../components/General/Seo";

import BlogNext from "../components/Blog/BlogNext";
import IFrameEmbed from "../components/General/IFrameEmbed";
import Image from "../components/Hacks/Image";

const BlogPost = ({ data }) => {
  const post = data.post;

  const advert1 = data?.advert1?.edges[0]
    ? data?.advert1?.edges[0].node
    : data?.advert2?.edges[0]
    ? data?.advert2?.edges[0]
    : false;

  const advert2 = data?.advert2?.edges[0]
    ? data?.advert2?.edges[0].node
    : false;

  const leftSide = post.frontmatter.advert1
    ? advert1
    : post.frontmatter.advert2
    ? advert2
    : false;
  const rightSide = post.frontmatter.advert2
    ? advert2
    : post.frontmatter.advert1
    ? advert1
    : false;

  const { meta, social } = useSiteMetadata();

  return (
    <Layout darkMenu={true}>
      <Seo
        title={
          post.frontmatter.title +
          meta.sitePageTitleSep +
          "Blog" +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={meta.siteUrl + post.fields.slug}
        description={
          post.frontmatter.metaDescription
            ? post.frontmatter.metaDescription
            : post.frontmatter.excerpt
            ? post.frontmatter.excerpt
            : meta.blogDescription
        }
        image={
          meta.siteUrl +
          (post.frontmatter.metaImage
            ? post.frontmatter.metaImage.publicURL
            : post.frontmatter.image && post.frontmatter.image.publicURL
            ? post.frontmatter.image.publicURL
            : meta.siteImage)
        }
        twitterTitle={post.frontmatter.title}
        facebookTitle={post.frontmatter.title}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />

      <BlogHeader
        title={post.frontmatter.title}
        excerpt={post.frontmatter.excerpt}
        tags={post.frontmatter.tags}
        date={post.frontmatter.date}
        author={post.fields.author}
        readtime={post.timeToRead}
        bg_colour={`bg-primary`}
        bg_image={``}
        featured_media={``}
        textColour={`text-primary`}
      />
      <main id="main" className={`journal_content`} data-aos="fade-up">
        {advert1 && post.frontmatter.includeSides && (
          <Sidebar
            leftBanner={leftSide.frontmatter.bannerVertical}
            leftImage={leftSide.frontmatter.bannerHorizontal}
            leftUrl={leftSide.frontmatter.url}
            leftTitle={leftSide.frontmatter.title}
            rightBanner={rightSide.frontmatter.bannerVertical}
            rightImage={rightSide.frontmatter.bannerHorizontal}
            rightUrl={rightSide.frontmatter.url}
            rightTitle={rightSide.frontmatter.title}
          />
        )}
        <OldContent
          content={post.html
            .replace(
              "[advert1]",
              renderToString(
                leftSide ? (
                  <div className="py-4 py-lg-5 text-center">
                    <Link to={leftSide.frontmatter.url}>
                      <Image
                        image={leftSide.frontmatter.bannerHorizontal.publicURL}
                        className="img-fluid"
                        alt={leftSide.frontmatter.title}
                      />
                      <p className="small mt-1">{leftSide.frontmatter.title}</p>
                    </Link>
                  </div>
                ) : (
                  <></>
                )
              )
            )
            .replace(
              "[advert2]",
              renderToString(
                rightSide ? (
                  <div className="py-4 py-lg-5 text-center">
                    <Link to={rightSide.frontmatter.url}>
                      <Image
                        image={rightSide.frontmatter.bannerHorizontal.publicURL}
                        className="img-fluid"
                        alt={rightSide.frontmatter.title}
                      />
                      <p className="small mt-1">
                        {rightSide.frontmatter.title}
                      </p>
                    </Link>
                  </div>
                ) : (
                  <></>
                )
              )
            )}
        />
      </main>
      {post.frontmatter.formUrl && (
        <IFrameEmbed
          url={post.frontmatter.formUrl}
          side_title={post.frontmatter.formTitle}
          side_content={post.frontmatter.formDescription}
        />
      )}
      {data.nextInCat.edges.length > 0 && (
        <BlogNext
          posts={data.nextInCat.edges}
          title={`More from ${post.frontmatter.tags}`}
          link="/blog/"
          linkText="View More"
        />
      )}

      {advert1 && (
        <PostContent
          leftBannerHorizontal={leftSide.frontmatter.bannerHorizontal}
          leftBannerVertical={leftSide.frontmatter.bannerVertical}
          leftImage={leftSide.frontmatter.image}
          leftUrl={leftSide.frontmatter.url}
          leftTitle={leftSide.frontmatter.title}
          leftExcerpt={leftSide.frontmatter.excerpt}
          rightBannerHorizontal={rightSide.frontmatter.bannerHorizontal}
          rightBannerVertical={rightSide.frontmatter.bannerVertical}
          rightImage={rightSide.frontmatter.image}
          rightUrl={rightSide.frontmatter.url}
          rightTitle={rightSide.frontmatter.title}
          rightExcerpt={rightSide.frontmatter.excerpt}
        />
      )}
      {data.nextOthers.edges.length > 0 && (
        <BlogNext
          posts={data.nextOthers.edges}
          title="More from the Blog"
          link="/blog/"
          linkText="View More"
          background={data.nextInCat.edges.length > 0 ? "bg-secondary" : ""}
          mini={true}
        />
      )}
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID(
    $id: String!
    $tags: String!
    $advert1: String!
    $advert2: String!
    $catOrder: Int!
  ) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        tags
        includeSides
        advert1
        advert2
        formUrl
        formTitle
        formDescription
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
        # tags
      }
      fields {
        slug
      }
    }

    advert1: allMarkdownRemark(filter: { fields: { slug: { eq: $advert1 } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            url: path
            excerpt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            bannerVertical
            bannerHorizontal
          }
        }
      }
    }

    advert2: allMarkdownRemark(filter: { fields: { slug: { eq: $advert2 } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            url: path
            excerpt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            bannerVertical
            bannerHorizontal
          }
        }
      }
    }

    nextInCat: allMarkdownRemark(
      limit: 3
      filter: {
        id: { nin: [$id] }
        fields: { slug: { regex: "//blog//" } }
        frontmatter: { tags: { eq: $tags } }
      }
      skip: $catOrder
      sort: { order: ASC, fields: frontmatter___catOrder }
    ) {
      edges {
        node {
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
            tags
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            # tags
          }
        }
      }
    }
    nextOthers: allMarkdownRemark(
      limit: 9
      filter: {
        id: { nin: [$id] }
        fields: { slug: { regex: "//blog//" } }
        frontmatter: {
          tags: { nin: [$tags, "Pregnancy By Week", "Baby By Month"] }
        }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
            tags
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            # tags
          }
        }
      }
    }
  }
`;
